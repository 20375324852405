<style lang="less">
@import "./login.less";
</style>

<template>
  <div id="loginPage" :style="{'background-image':'url('+ loginBg +')'}">

    <!-- sign in -->
    <div
      class="login-card"
      v-show="loginStatus == 0"
      @keydown.enter="handleSubmit"
    >
      <div v-if="logo && logo!==null && logo!=='null' && logo!==''" class="login-logo"><img :src="logo" /></div>
      <div v-if="(loginTitle==null || loginTitle=='null' || !loginTitle) && subdomainName!=='ylepp'" class="login-card-header">

        {{ $t("x_login_site_title") }}
      </div>
      <div v-if="(loginTitle!==null && loginTitle!=='null' && loginTitle) && subdomainName!=='ylepp'" class="login-card-header">
        {{ loginTitle }}
      </div>
      <div class="login-card-body">
        <Form ref="loginForm" :model="loginForm" :rules="loginFormRuleValidate">
          <FormItem prop="username">
            <Input v-model="loginForm.username" :placeholder="$t('x_username_or_email')"></Input>
          </FormItem>
          <FormItem prop="password">
            <Input password type="password" v-model="loginForm.password" :placeholder="$t('x_password')"></Input>
          </FormItem>

        </Form>
      </div>
      <div class="login-card-footer" :class="subdomainName!=='ylepp'?'':'center'">
        <Button type="text" size="large" class="login-btn" @click="handleSubmit" :loading="showLoading">
          <span v-if="!showLoading">{{ $t("x_login") }}</span>
          <span v-else>Logining...</span>
        </Button>

      </div>
<!--      <div class="social-login">-->
<!--        <div class="forget-pwd">-->
<!--          <span @click="forgetpwd">{{ $t("x_forget_pwd") }}</span>-->
<!--        </div>-->
<!--      </div>-->
    </div>
    <!-- sign up -->
    <div class="login-card" v-show="loginStatus == 1">
      <div v-if="logo && logo!==null && logo!=='null' && logo!==''" class="login-logo"><img :src="logo" /></div>
      <div v-if="loginTitle==null || loginTitle=='null' || !loginTitle" class="login-card-header">

        {{ $t('x_sign_up_title') }}
      </div>
      <div v-else class="login-card-header">
        {{ loginTitle }}
      </div>
      <div class="login-card-body">
        <Form ref="signupForm" hide-required-mark :model="signupForm" :rules="signupFormRuleValidate">
          <FormItem prop="username">
            <Input v-model="signupForm.username" autocomplete="off" :placeholder="$t('x_signup_name')"></Input>
          </FormItem>
          <FormItem prop="password">
            <Input
              autocomplete="off"
              password
              type="password"
              v-model="signupForm.password"
              :placeholder="$t('x_password')"
            ></Input>
          </FormItem>

          <FormItem prop="password2">
            <Input
              ref="pws"
              autocomplete="off"
              password
              type="password"
              v-model="signupForm.password2"
              :placeholder="$t('x_confirm_password')"
              @on-focus="ifPassword"
            ></Input>
          </FormItem>
          <FormItem prop="mobile">
            <Input v-model="signupForm.mobile" autocomplete="off" :placeholder="$t('x_phone')" />
          </FormItem>
          <FormItem prop="email">
            <div class="input-row">
              <Input autocomplete="off" v-model="signupForm.email" :placeholder="$t('x_username_or_email')" />
              <Button
                type="primary"
                v-show="countdown == 60"
                @click="checkEmail"
                >{{ $t('x_send_verification_code') }}</Button
              >
              <Button v-show="countdown != 60" disabled>Send again {{ countdown }}</Button>
            </div>
          </FormItem>
          <FormItem prop="emailCaptcha">
            <Input autocomplete="off" v-model="signupForm.emailCaptcha" :placeholder="$t('x_verification_code')" />
          </FormItem>

        </Form>
      </div>
      <div class="login-card-footer">
        <Button
          type="primary"
          size="large"
          long
          class="account-btn"
          @click="submitSignupForm"
        >
          <Spin v-if="showLoading">
            <Icon type="ios-loading" size="18" class="spin-icon-load"></Icon>
          </Spin>
          <div v-if="!showLoading">{{ $t("x_signup") }}</div>
        </Button>
      </div>
      <div class="forget-pwd">
        <span @click="toSignin">{{ $t('x_back_to_login') }}</span>
      </div>
    </div>
    <!-- forget password -->
<!--    <div class="login-card" v-show="loginStatus == 2">-->
<!--      <div v-if="logo && logo!==null && logo!=='null' && logo!==''" class="login-logo"><img :src="logo" /></div>-->
<!--      <div v-show="!forgetPwdFlag">-->
<!--        <div class="login-card-header">-->

<!--          {{ $t("x_forget_pwd") }}-->
<!--        </div>-->
<!--        <div class="login-card-body">-->
<!--          <div class="forget-tip">{{ $t("x_forget_pwd_tip") }}</div>-->
<!--          <Form ref="forgetPwdForm" hide-required-mark :model="forgetPwdForm" :rules="forgetPwdFormRuleValidate">-->
<!--            <FormItem prop="email">-->
<!--              <Input v-model="forgetPwdForm.email" :placeholder="$t('x_username_or_email')" />-->
<!--            </FormItem>-->

<!--          </Form>-->
<!--        </div>-->
<!--        <div class="login-card-footer">-->
<!--          <Button-->
<!--            @click="submitForgetPwdForm"-->
<!--            type="primary"-->
<!--            long-->
<!--            class="account-btn"-->
<!--            size="large"-->
<!--          >-->
<!--            <Spin v-if="showLoading">-->
<!--              <Icon type="ios-loading" size="18" class="spin-icon-load"></Icon>-->
<!--            </Spin>-->
<!--            <div v-if="!showLoading">-->
<!--              {{ $t("x_submit") }}-->
<!--            </div>-->
<!--          </Button>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div v-show="forgetPwdFlag">-->
<!--        <div class="login-card-header">-->
<!--          <div style="margin-bottom: 20px"><Icon type="ios-checkmark-circle-outline" size="100" color="#68D0D0" /></div>-->
<!--          {{ $t("x_pwd_reset") }}-->
<!--        </div>-->
<!--        <div class="login-card-body">-->
<!--          <div class="forget-tip">{{ $t("x_forget_pwd_tip2")}}</div>-->
<!--        </div>-->
<!--      </div>-->
<!--      <div class="forget-pwd">-->
<!--        <span @click="toSignin">{{ $t('x_back_to_login') }}</span>-->
<!--      </div>-->
<!--    </div>-->

    <video v-if="loginBg==null || loginBg=='null' || !loginBg" id="backgroundVid" style="width: 100%;height: 100%;object-fit: cover;position: absolute;top: 0; left: 0;z-index:1" autoplay loop muted>
      <source :src="PATH_TO_MP4" type="video/mp4"/>
      浏览器不支持 video 标签，建议升级浏览器。
      <source :src="PATH_TO_WEBM" type="video/webm"/>
      浏览器不支持 video 标签，建议升级浏览器。
    </video>

  </div>
</template>

<script>
import Cookies from "js-cookie";
import _ from "lodash";
import Service from "../../../libs/service.js";
import { v4 as uuidv4 } from "uuid";
import { isEmail } from "@/libs/validate";

import mp4Path from "/public/images/home/VR3D_Login_page_BG.mp4"
import webmPath from "/public/images/home/bg.webp"

export default {
  data() {
    let validateEmail = (rule, value, callback) => {
      // var email = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
      var email = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

      if (!value) {
        return callback(new Error(this.$t('x_empty_err')))
      } else if (!email.test(value)) {
        callback(new Error(this.$t('x_email_invlidate')))
      } else {
        return callback()
      }
    };

    let loginFormValidateUsername = (rule, value, callback) => {
      // var email = /^([a-zA-Z0-9]+[-_\.]?)+@[a-zA-Z0-9]+\.[a-z]+$/;
      var email = /^\w+((-\w+)|(\.\w+))*@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;

      if (!value) {
        return callback(new Error(this.$t('x_username_empty_err')))
      } else if (!email.test(value)) {
        callback(new Error(this.$t('x_email_invlidate')))
      } else {
        return callback()
      }
    };

    let validatePassword = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('x_password_empty_err')))
      } else {
        return callback()
      }
    };

    let validatePassword2 = (rule, value, callback) => {
      console.log('*******', value , this.signupForm.password)
      if (!value) {
        return callback(new Error(this.$t('x_comfirm_password_empty_err')))
      } else if (value !== this.signupForm.password ) {
        return callback(new Error(this.$t('x_password_err')))
      } else {
        return callback()
      }
    };

    let validateUsername = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('x_username_empty_err')))
      } else {
        return callback()
      }
    };

    let validateEmpty = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('x_empty_err')))
      } else {
        return callback()
      }
    };

    return {
      countdown: 60,
      countdownTimer: null,
      company: {
        logo: "",
        name: "",
      },
      errorMessage: "",
      captchaUrl: "",
      uuidVal: "",
      loginStatus: 0,
      showLoading: false,
      forgetPwdFormRuleValidate: {
        email: [
          // { required: true, message: this.$t('x_empty_err'), trigger: 'blur' },
          // { type: 'email', message: this.$t("x_email_invlidate"), trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' },
        ],
      },
      forgetPwdForm: {
        email: "",
      },
      forgetPwdFlag: false,
      loginFormRuleValidate: {
        username: [
          // { required: true, message: this.$t('x_username_empty_err'), trigger: 'blur' },
          // { type: 'email', message: this.$t("x_email_invlidate"), trigger: 'blur' },
          { validator: loginFormValidateUsername, trigger: 'blur' },
        ],
        password: [
          // { required: true, message: this.$t('x_password_empty_err'), trigger: 'blur' },
          { validator: validatePassword, trigger: 'blur' },
        ],
        captcha: [
          // { required: true, message: this.$t('x_empty_err'), trigger: 'blur' },
          { validator: validateEmpty, trigger: 'blur' },
        ],
      },
      loginForm: {
        captcha: "",
        uuid: "",
        username: "",
        password: "",
      },
      signupFormRuleValidate: {
        username: [
          // { required: true, message: this.$t('x_username_empty_err'), trigger: 'blur' },
          { validator: validateUsername, trigger: 'blur' },
        ],
        password: [
          // { required: true, message: this.$t('x_password_empty_err'), trigger: 'blur' },
          { validator: validatePassword, trigger: 'blur' },
        ],
        password2: [
          // { required: true, message: this.$t('x_comfirm_password_empty_err'), trigger: 'blur' },
          { validator: validatePassword2, trigger: 'blur' },
        ],
        email: [
          // { required: true, message: this.$t('x_empty_err'), trigger: 'blur' },
          // { type: 'email', message: this.$t("x_email_invlidate"), trigger: 'blur' },
          { validator: validateEmail, trigger: 'blur' },
        ],
        emailCaptcha: [
          // { required: true, message: this.$t('x_empty_err'), trigger: 'blur' },
          { validator: validateEmpty, trigger: 'blur' },
        ],
        mobile: [
          // { required: true, message: this.$t('x_empty_err'), trigger: 'blur' },
          { validator: validateEmpty, trigger: 'blur' },
        ],
      },
      signupForm: {
        username: "",
        password: "",
        password2: "",
        email: "",
        emailVerified: false,
        emailCaptcha: "",
        mobile: "",
      },
      pwdScore: 1,
      isPwdRobustTest: false,
      robustTestResult: this.$t("x_pwd_very_weak"),
      currentLanguage: "en-US",
      languages: [
        {
          k: "English",
          v: "en-US",
        },
        {
          k: "繁體中文",
          v: "zh-TW",
        },
        {
          k: "简体中文",
          v: "zh-CN",
        },
      ],

      PATH_TO_MP4: mp4Path,
      PATH_TO_WEBM: webmPath,

      loginTitle: null,
      loginBg: null,
      logo: null,
      subdomainName: "",
    };
  },
  created() {
    var hostitems = window.location.host.split('.');
    var isLocalhost = window.location.host.indexOf('localhost') > -1;
    if(isLocalhost && hostitems.length > 1){
      this.subdomainName = hostitems[0];
    }else if(!isLocalhost && hostitems.length > 2){
      this.subdomainName = hostitems[0];
    }

    this.loginTitle = Cookies.get("login_title");
    this.loginBg = Cookies.get("login_bg");
    this.logo = Cookies.get("login_logo");
  },
  methods: {
    init() {
      if(this.loginBg==null || this.loginBg=='null' || !this.loginBg) {
        // Disable right click on the video
        document.getElementById('backgroundVid').addEventListener('contextmenu', function(e) {
          e.preventDefault()
        });
      }

      this.currentLanguage = window.localStorage.getItem("language");

      if (!window.root_platform) {
        this.getCompanyInfo(window.subdomainName);
      }

      this.getCaptcha();
    },
    getCompanyInfo(name) {
      var that = this;

      if(window.isSubDomainSupport) {
        Service.request(
          "get",
          `/sys/company/logo?website=${name}`,
          {},
          function (dt) {
            that.company = dt.data;
          },
          function (err) {
            var hostitems = window.location.host.split(".");
            hostitems.shift();
            var path = hostitems.join(".");

            _.delay(function () {
              window.location.replace(
                `${window.location.protocol}//${path}/#/login`
              );
            }, 100);
          }
        );
      }
    },
    ifPassword() {
      if(_.trim(this.signupForm.password) == '') {
        this.$refs['pws'].blur();
        this.$refs["signupForm"].validateField('password')
      }
    },
    getCaptcha() {
      this.uuidVal = uuidv4();
      this.captchaUrl = `${window.Baseurl}/sys/captcha?uuid=${this.uuidVal}`;
    },
    checkEmail() {
      var that = this;
      var email = _.trim(this.signupForm.email);

      if (email != "" && isEmail(email)) {
        this.countdownTimer = setInterval(function () {
          if (that.countdown == 1) {
            that.countdown = 60;
            clearInterval(that.countdownTimer);
          } else {
            that.countdown--;
          }
        }, 1000);

        Service.request(
          "get",
          `/sys/user/signup/check?type=user_email&value=${email}`,
          {},
          function (dt) {
            if (dt.data == 1) {
              that.sendVerifyCode();
            } else {
              that.$Message.error(`${email} already registered`);
            }
          },
          function (err) {}
        );
      } else {
        this.$Message.error(this.$t("x_email_invlidate"));
      }
    },
    sendVerifyCode() {
      var that = this;
      that.signupForm.emailVerified = false;

      var email = _.trim(this.signupForm.email);

      Service.request(
        "get",
        `/sys/user/signup/send?to=${email}`,
        {},
        function (dt) {
          if (dt.code == 0) {
            that.$Message.success(that.$t('x_send_verification_code_tip'));
          }
        },
        function (err) {}
      );
    },
    handleSubmit() {
      var that = this;
      var username = _.trim(that.loginForm.username);
      var password = _.trim(that.loginForm.password);
      var captcha = _.trim(that.loginForm.captcha);
      // var msg = "";
      // if (
      //   [username, password, captcha].some((item) => {
      //     return item === "";
      //   })
      // ) {
      //   msg = this.$t("x_empty_err");
      // } else if (!isEmail(username)) {
      //   msg = this.$t("x_email_invlidate");
      // }

      // if (msg != "") {
      //   that.$Message.error(msg);
      //   return;
      // }
      this.$refs['loginForm'].validate((valid) => {
        if (valid) {
          that.doLogin(username, password, captcha);
        } else {
          // this.$Message.error('Fail!');
          return;
        }
      })
    },
    doLogin(username, password, captcha) {
      var that = this;
      var data = {
        username: username,
        password: password,
        // captcha: captcha,
        uuid: this.uuidVal,
      };

      this.showLoading = true;

      Service.request(
        "post",
        "/sys/login",
        data,
        function (dt) {
          that.showLoading = false;
          Cookies.set("sdms_token", dt.token);
            Cookies.set("access", dt.data.roleId);
            Cookies.set("user", dt.data.username);
            Cookies.set("status", dt.data.status);
            Cookies.set("userId", dt.data.userId);
            Cookies.set("companyId", dt.data.companyId);
          that.$router.push(
              {
                name: 'workspacelite',
              }
          )
        },
        function (err) {
          that.getCaptcha();
          that.showLoading = false;
        }, {
          skipSuccess: true
        }
      );
    },

    toProjectWorkspace(project){
      let redirectName = 'projectoverview';
      switch(project.template) {
        case 'smart_site':
          redirectName = 'smartsite_projectoverview';
          break;
        case 'plan':
          redirectName = 'planviewer';
          break;
        case 'ai':
          redirectName = 'aioverview';
          break;
        case 'ai_detection':
          redirectName = 'teachable';
          break;
        case 'sdms':
        default:
          redirectName = 'projectoverview';
      }

      this.$router.push({
        name: redirectName,
        query:{ prjid: project.projectId }
      });
    },

    getUerInfo() {
      var that = this;
      Service.request("get", "/sys/user/info", "", function (dt) {
        that.showLoading = false;
        if (dt.data.status == 1 && dt.data.roleId) {
          if (dt.data.settings && dt.data.settings != "") {
            dt.data.settings = JSON.parse(dt.data.settings);
            if (
              dt.data.settings.theme &&
              (dt.data.settings.theme == "sdmslight" ||
                dt.data.settings.theme == "sdmsdark")
            ) {
              that.changeTheme(dt.data.settings.theme);
            }
          }

          if (window.root_platform) {
            that.$router.push({
              name: "hub",
            });
          } else {
            Cookies.set("access", dt.data.roleId);
            Cookies.set("user", dt.data.username);
            Cookies.set("status", dt.data.status);
            Cookies.set("userId", dt.data.userId);
            Cookies.set("companyId", dt.data.companyId);

            that.$router.push({
              name: "projects",
            });
          }
        }
      });
    },
    changeTheme(t) {
      let themeLink = document.querySelector("body");
      themeLink.setAttribute("class", t);
      this.theme = window.localStorage.theme = t;
    },

    submitSignupForm() {
      var that = this;

      var username = _.trim(that.signupForm.username);
      var password = _.trim(that.signupForm.password);
      var email = _.trim(that.signupForm.email);
      var mobile = _.trim(that.signupForm.mobile);
      var code = _.trim(that.signupForm.emailCaptcha);

      // var msg = "";

      // if (
      //   [username, password, email, mobile, code].some((item) => {
      //     return item === "";
      //   })
      // ) {
      //   msg = this.$t("x_empty_err");
      // } else if (!isEmail(email)) {
      //   msg = this.$t("x_email_invlidate");
      // }

      // if (msg != "") {
      //   that.$Message.error(msg);
      //   return;
      // }

      this.$refs['signupForm'].validate((valid) => {
        if (valid) {
          this.toVerifyEmail(email, code);
        } else {
          // this.$Message.error('Fail!');
          return;
        }
      })
    },
    toVerifyEmail(email, code) {
      var that = this;
      Service.request(
        "get",
        `/sys/user/signup/check?type=email_captcha&value=${email}::${code.toLocaleUpperCase()}`,
        {},
        function (dt) {
          if (dt.data == 1) {
            that.doRegister();
          } else {
            that.$Message.error(`Verify code is not correct`);
          }
        },
        function (err) {}
      );
    },
    doRegister() {
      var that = this;
      that.showLoading = true;

      var data = {
        username: _.trim(that.signupForm.username),
        password: _.trim(that.signupForm.password),
        email: _.trim(that.signupForm.email),
        mobile: _.trim(that.signupForm.mobile),
      };

      Service.request(
        "post",
        "/sys/user/signup",
        data,
        function (dt) {
          that.showLoading = false;
          if (dt.token) {
            Cookies.set("sdms_token", dt.token);
            if (window.root_platform) {
              that.$router.push({
                name: "hub",
              });
            } else {
              Cookies.set("access", dt.data.roleId);
              Cookies.set("user", dt.data.username);
              Cookies.set("status", dt.data.status);
              Cookies.set("userId", dt.data.userId);
              Cookies.set("companyId", dt.data.companyId);

              that.$router.push({
                name: "projects",
              });
            }
          }
        },
        function (err) {
          that.showLoading = false;
        }
      );
    },
    // forgetpwd() {
    //   this.resetForgetPwdForm();
    //   this.loginStatus = 2;
    // },
    toSignin() {
      this.resetLoginForm();
      this.loginStatus = 0;
    },
    submitForgetPwdForm() {
      var that = this;

      // var email = _.trim(that.forgetPwdForm.email);
      // var msg = "";
      // if (
      //   [email].some((item) => {
      //     return item === "";
      //   })
      // ) {
      //   msg = this.$t("x_empty_err");
      // } else if (!isEmail(email)) {
      //   msg = this.$t("x_email_invlidate");
      // }

      // if (msg != "") {
      //   that.$Message.error(msg);
      //   return;
      // }

      this.$refs['forgetPwdForm'].validate((valid) => {
        if (valid) {
          that.showLoading = true;
          var data = {
            email: _.trim(this.forgetPwdForm.email),
          };

          Service.request(
            "put",
            `/sys/user/forget`,
            data,
            function (dt) {
              that.showLoading = false;
              that.forgetPwdFlag = true;
              // that.loginStatus = 0;
              that.resetForgetPwdForm();
            },
            function (err) {
              that.showLoading = false;
            }
          );
        } else {
          // this.$Message.error('Fail!');
          return;
        }
      })
    },
    resetForgetPwdForm() {
      this.$refs["forgetPwdForm"].resetFields();
      this.forgetPwdForm = {
        email: "",
      };
    },
    resetLoginForm () {
      this.$refs["loginForm"].resetFields();
      this.loginForm = {
        captcha: "",
        uuid: "",
        username: "",
        password: "",
      };
    },
    resetUserForm() {
      this.$refs["signupForm"].resetFields();
      this.signupForm = {
        username: "",
        password: "",
        email: "",
        emailVerified: false,
        emailCaptcha: "",
        mobile: "",
      };
      // Reinit the pwd robustness test
      // Generic.resetPwdRobustness(this);
    },
  },
  watch:{
    "$i18n.locale": function () {
      this.$refs['forgetPwdForm'].fields.forEach(item => {
        if(item.validateState === 'error'){
          this.$refs['forgetPwdForm'].validateField(item.prop)
        }
      }),
      this.$refs['loginForm'].fields.forEach(item => {
        if(item.validateState === 'error'){
          this.$refs['loginForm'].validateField(item.prop)
        }
      }),
      this.$refs['signupForm'].fields.forEach(item => {
        if(item.validateState === 'error'){
          this.$refs['signupForm'].validateField(item.prop)
        }
      })
    }
  },
  mounted() {
    var bodyClass = "sdmsdark";
    document.body.className = bodyClass;
    this.init();
  },
};
</script>
